import { Controller } from "stimulus"
import List from "list.js"

export default class extends Controller {
  connect() {
    var options = {
      valueNames: [ 'name', 'phone', 'giftcertnum' ]
    };
  
    var ticketList = new List("ticket-list", options);
  }
}
