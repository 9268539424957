// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    console.log('connected')
  }

  showPayment(event) {
    var number = event.currentTarget.dataset.number;
    alert(number);
  }

  editName(event) {
    var name = event.target.innerText;
    var number = event.currentTarget.dataset.number;

    name = prompt('Ret kundens navn', name)
    if(name !== null){
      this.putRecord(number, 'name', name)
    }
  }

  editPhone(event) {
    var name = event.target.innerText;
    var number = event.currentTarget.dataset.number;

    name = prompt('Ret kundens telefonnummer', name)
    if(name !== null){
      this.putRecord(number, 'phone_no', name)
    }
  }

  editDate(event) {
    var number = event.currentTarget.dataset.number;

    name = prompt('Ret dato med x dage. Fx. 2 eller -14', '0')
    if(name !== null){
      this.putRecord(number, 'days', name)
    }
  }

  putRecord(id, field, value) {
    const formData = new FormData();
    formData.append('ticket['+field+']', value);

    fetch('/admin/tickets/' + id, {
      method: 'PUT',
      body: formData,
      headers: {
        'X-CSRF-Token': document.querySelectorAll('meta[name="csrf-token"]')[0].getAttribute("content")
      }
    }).then((response) => {
      if (response.ok) {
      } else {
        alert('Fejl')
      }
    })
  }
}
